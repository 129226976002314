import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/main/Navbar';
import Sidebar from './components/main/Sidebar';
import './App.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

// Lazy load components
const Home = lazy(() => import('./components/pages/Home'));
const Products = lazy(() => import('./components/pages/Prodcuts'));
const ProductsPpr = lazy(() => import('./components/pages/products/ppr'));
const ProductsOrg = lazy(() => import('./components/pages/products/org'));
const ProductsPro = lazy(() => import('./components/pages/products/pro'));
const ProductsEle = lazy(() => import('./components/pages/products/ele'));
const ProductsPP = lazy(() => import('./components/pages/products/pp'));

class App extends Component {
  state = {
    isSidebarOpen: false,
    isMobileView: false,
  };

  componentDidMount() {
    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkScreenWidth);
  }

  checkScreenWidth = () => {
    const isMobile = window.innerWidth <= 768;
    this.setState({
      isMobileView: isMobile,
      isSidebarOpen: false,
    });
  };

  toggleSidebar = () => {
    if (this.state.isMobileView) {
      this.setState((prevState) => ({
        isSidebarOpen: !prevState.isSidebarOpen,
      }));
    }
  };

  render() {
    return (
      <Router>
        <div className="App">
          <Navbar toggleSidebar={this.toggleSidebar} />
          <Sidebar isOpen={this.state.isSidebarOpen} toggleSidebar={this.toggleSidebar} />
          <main>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Products" element={<Products />} />
                <Route path="/Products/ppr" element={<ProductsPpr />} />
                <Route path="/Products/org" element={<ProductsOrg />} />
                <Route path="/Products/pro" element={<ProductsPro />} />
                <Route path="/Products/ele" element={<ProductsEle />} />
                <Route path="/Products/pp" element={<ProductsPP />} />
                <Route path="/services" element={<Home />} />
              </Routes>
            </Suspense>
          </main>
        </div>
      </Router>
    );
  }
}

export default App;